.wrapper {
    /* display: flex; */
    margin: 2vmax 2vmax 2vmax 2vmax;
    /* align-items: left; */
    padding: var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-sm);
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
    border: 0.0625vmax solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-8));
  
    @media (max-width: $mantine-breakpoint-sm) {
      flex-direction: column-reverse;
      padding: var(--mantine-spacing-xl);
    }
  }
  
  .body {
    padding-right: calc(var(--mantine-spacing-xl) * 4);
  
    @media (max-width: $mantine-breakpoint-sm) {
      padding-right: 0;
      margin-top: var(--mantine-spacing-xl);
    }
  }
  
  .title {
    font-size: 1.3vmax;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    line-height: 1;
    margin-bottom: var(--mantine-spacing-md);
  }
  